<template>
  <div style="height: 100%">
    <v-sheet color="red darken-2" height="200" style="width: 100%"> </v-sheet>
    <v-container class="mt-n16 mx-auto">
      <v-card min-width="200px" max-width="600px" outlined class="mt-n3 mx-auto">
        <v-card-title class="mb-n6"> Panel disabled </v-card-title>
        <v-card-text class="mt-n4">
        The panel is currently disabled due to {{ $store.state.disabled }}
        </v-card-text>
        <v-img :src="img" class="mx-auto mt-7" max-width="400"></v-img>
        <v-row class="mb-3">
          <v-btn @click="reload" class="mx-auto mb-1 mt-3 info"> Refersh </v-btn>
        </v-row>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "App",
  data: () => ({
    loading: true,
    img: require("@/assets/conifer-access-denied.png"),
    invite: "",
  }),
  methods: {
    reload: function () {
      window.location.reload();
    },
  },
};
</script>

<template>
  <div>
    <v-sheet color="red darken-2" height="200" style="width: 100%">
      <v-container>
        <v-layout class="mt-14">
          <div class="white--text">
            <p class="text-h4 font-weight-bold">Hi {{ this.$store.state.user.name }},</p>
            <p class="text-body-1 font-weight-bold mt-n6 gray">Welcome to GRPanel</p>
          </div>
        </v-layout>
      </v-container>
    </v-sheet>
    <v-container class="mt-n16">
      <v-row>
        <v-col>
          <v-card :loading="ban.loading" outlined>
            <v-card-text> Ban </v-card-text>
            <v-form class="mx-4" ref="form">
              <v-text-field
                :disabled="ban.loading"
                v-model="ban.username"
                filled
                @input="ban.found = true"
                :rules="[(v) => !!ban.found || 'User not found']"
                hide-details="auto"
                label="Username"
              >
              </v-text-field>
              <v-text-field
                :disabled="ban.loading"
                v-model="ban.time"
                filled
                @input="ban.found = true"
                :rules="[(v) => !!v || 'Please enter time']"
                hide-details="auto"
                class="mt-2"
                label="Time"
              >
              </v-text-field>
              <v-textarea
                :disabled="ban.loading"
                v-model="ban.reason"
                filled
                rows="2"
                :rules="[(v) => !!v || 'Enter a reason']"
                class="mt-2"
                label="Reason"
                hide-details="auto"
              >
              </v-textarea>
              <fileUpload v-model="ban.proof"> </fileUpload>
            </v-form>
            <v-row class="pb-6 mx-4 mt-3">
              <v-btn
                text
                class="ml-auto"
                @click="cban"
                :disabled="ban.loading"
                color="info"
              >
                Ban
              </v-btn></v-row
            ></v-card
          >
          <v-card class="mt-3" @submit.prevent="unbanu" :loading="unban.loading" outlined>
            <v-card-text> Unban </v-card-text>
            <v-form class="mx-4" ref="frm">
              <v-text-field
                :disabled="unban.loading"
                v-model="unban.username"
                filled
                @input="unban.found = true"
                :rules="[(v) => !!unban.found || 'User not found']"
                hide-details="auto"
                label="Username"
              >
              </v-text-field>
            </v-form>
            <v-row class="pb-6 mx-4 mt-3">
              <v-btn text class="ml-auto" @click="unbanu" :disabled="unban.loading" color="info">
                Unban
              </v-btn></v-row
            ></v-card
          >
        </v-col>
      </v-row>
    </v-container>
    <v-snackbar v-model="toast.open">
      {{ toast.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="toast.color"
          text
          v-bind="attrs"
          timeout="2000"
          @click="toast.open = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import fileUpload from "@/components/fileUpload";

export default {
  name: "Home",

  components: {
    fileUpload
  },
  data: () => ({
    ban: {
      username: "",
      reason: "",
      proof: "",
      time: "0",
      found: true,
      loading: false,
    },
    toast: {
      message: "",
      color: "success",
      open: false,
    },
    unban: {
      username: "",
      found: true,
      loading: false,
    },
  }),
  methods: {
    async cban() {
      this.ban.loading = true;
      try {
        await this.$http.post("/ban", {
          target: this.ban.username,
          date: this.ban.time,
          reason: this.ban.reason,
          proof: this.ban.proof,
        });
      } catch (e) {
        this.toast.message = "Something went wrong";
        this.toast.color = "error";
        this.toast.open = true;
        return;
      }
      this.toast.message = "Sucessfully banned user";
      this.toast.color = "success";
      this.toast.open = true;
      this.ban = {
        username: "",
        reason: "",
        proof: "",
        time: "0",
        found: true,
        loading: false,
      };
    },
    async unbanu() {
      this.unban.loading = true;
      try {
        await this.$http.delete("/ban/" + this.unban.username);
      } catch (e) {
        this.unban.loading = false;
        if (e.response.data?.message) {
          console.log(e.response.status)
          if (e.response.status === 404) {
            this.unban.found = false
            this.$refs.frm.validate()
          }
          this.toast.message = e.response.data.message;
          this.toast.color = "error";
          this.toast.open = true;
          return;
        }
        this.toast.message = "Something went wrong";
        this.toast.color = "error";
        this.toast.open = true;
        return;
      }
      this.toast.message = "Sucessfully unbanned user";
      this.toast.color = "success";
      this.toast.open = true;
      this.unban = {
        username: "",
        found: true,
        loading: false,
      };
    },
  },
};
</script>

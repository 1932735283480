<template>
  <v-app dark>
    <v-progress-circular
      :size="50"
      color="amber"
      class="mx-auto my-auto"
      indeterminate
      v-if="loading"
    ></v-progress-circular>
    <component v-if="!loading" :is="layout">
      <router-view />
    </component>
  </v-app>
</template>

<script>
export default {
  name: "App",
  computed: {
    layout() {
      return (this.$route.meta.layout || "default") + "-layout";
    },
  },
  data: () => ({
    loading: true,
    invite: "",
  }),
  mounted: function () {
    this.loading = true;
    console.log(this.$route)
    if (this.$route.path === '/callback') {
      console.log('ca`lback');
      //this.$cookies.set("connect.sid", this.$route.query.cookie, null, null, false);
      this.$router.push('/');
      window.location.reload()
    } 

    this.$http.get("/profile", {
      withCreditentials: true,
    }).then(
      (res) => {
        let data = {
          name: res.data.displayName,
          otherInfo: res.data.uinfo,
          roblox: res.data.roblox,
          permission: res.data.permission,
          isDeveloper: res.data.isDeveloper,
          dark: false,
          pfp: res.data.pfp,
        };
        this.$store.commit("setUser", data);
        if (this.$route.path == "/signin") {
          this.$router.push("/");
        }

        if (this.$route.path == "/error") {
          this.$router.push("/");
        }
        console.log(this.$store.state.user);
        this.loading = false;
      },
      (err) => {
        err;
        this.loading = false;
        if (!err.response) {
          if (this.$route.path !== "/error") {
            this.$router.push("/error");
          }
          return;
        }

        if (err.response.status === 503) {
          this.$store.commit("setDisabled", err.response.data.message);
          this.$router.push("/disabled");
          return;
        }
        console.log("err");
        if (this.$route.path !== "/signup" && this.$route.path !== "/signin") {
          this.$router.push("/signin");
        }
      }
    ); 
  },
};
</script>

<template>
  <div style="height: 100%">
    <v-sheet color="red darken-2" height="200" style="width: 100%"> </v-sheet>
    <v-container class="mt-n10 mx-auto">
      <v-card
        min-width="200px"
        max-width="600px"
        outlined
        class="mt-n3 mx-auto"
        loading
      >
        <v-card-title class="mb-n5"> Redirecting to discord... </v-card-title>
        <div class="mx-4 mb-4">
          <v-card-text class="mt-n10 ml-n4 mb-n4 mt-n2 mt-n2 grey--text">
            Please wait well we redirect you to the login page
          </v-card-text>
        </div>
      </v-card>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Welcome",
  data: () => ({
    e1: 1,
    username: "",
    password: "",
    valid: true,
    loading: false,
    error: false,
    group: "",
    name: "",
  }),
  methods: {
  }, mounted () {
    console.log(this.$http.defaults);
    setTimeout(() => {
      window.location = `${this.$http.defaults.baseURL}/login`;
    }, 600)
    //window.location = `${this.$http.defaults.baseURL}/login`;
  }
};
</script>

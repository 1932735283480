<template>
  <div>
    <v-sheet color="red darken-2" height="200" style="width: 100%">
      <v-container>
        <v-layout class="mt-14">
          <div class="white--text">
            <p class="text-h4 font-weight-bold">Hi {{ this.$store.state.user.name }},</p>
            <p class="text-body-1 font-weight-bold mt-n6 gray">Welcome to GRPanel</p>
          </div>
        </v-layout>
      </v-container>
    </v-sheet>
    <v-container class="mt-n16">
      <!-- <v-row v-if="$store.state.user.permission >= 4">
        <v-col>
          <v-card outlined>
            <v-card-text> Bans this week </v-card-text>
            <p class="text-h1 ml-4 mt-n2">71</p></v-card
          ></v-col
        >
        <v-col>
          <v-card outlined>
            <v-card-text> Reports this week </v-card-text>
            <p class="text-h1 ml-4 mt-n2">9</p></v-card
          ></v-col
        >
      </v-row> -->
      <v-row class="mt-n2">
        <v-col order="last" cols="12" sm="4">
          <v-card to="/reports" outlined v-ripple>
            <v-layout>
              <div>
                <v-card-title> 📋 Reports </v-card-title>
                <v-card-subtitle style="max-width: 1500px" class="mt-n6 ctext grey--text">
                  View your reports
                </v-card-subtitle>
              </div>
              <v-spacer></v-spacer>
            </v-layout>
          </v-card>
        </v-col>
        <v-col order="last" cols="12" sm="4">
          <v-card to="/lookup" outlined v-ripple>
            <v-layout>
              <div>
                <v-card-title> 🔎 Lookup </v-card-title>
                <v-card-subtitle style="max-width: 1500px" class="mt-n6 ctext grey--text">
                  Lookup a users ban history
                </v-card-subtitle>
              </div>
              <v-spacer></v-spacer>
            </v-layout>
          </v-card>
        </v-col>
        <v-col order="last" cols="12" sm="4">
          <v-card outlined to="/images" v-ripple>
            <v-layout>
              <div>
                <v-card-title> 🎆 Images </v-card-title>
                <v-card-subtitle style="max-width: 1500px" class="mt-n6 ctext grey--text">
                  Click to view your GRP images
                </v-card-subtitle>
              </div>
              <v-spacer></v-spacer>
            </v-layout>
          </v-card>
        </v-col>
      </v-row>
      <v-card class="mt-3" outlined>
        <v-row class="mx-auto mb-5 mt-5">
          <v-img
            src="../assets/waiting-6.png"
            class="mx-auto mt-7"
            max-width="400"
            v-if="clicks < 5"
            ripple
            style="cursor: pointer"
            @click="clicks++"
          ></v-img>
          <v-img
            src="https://freepngimg.com/thumb/ariana_grande/21486-4-ariana-grande-transparent.png"
            class="mx-auto mt-7 mb-3"
            v-if="clicks === 5"
            max-width="400"
          ></v-img>
          <v-card-text class="text-center mt-n3 mb-n4">
            {{ clicks === 5 ? "Ariana Grande graphic here" : "There is nothing here so heres a graphic instead"}}
          </v-card-text>
        </v-row></v-card
      >
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Home",
  data: () => ({
    clicks: 0,
  }),
  components: {},
};
</script>

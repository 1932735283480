import Vue from 'vue';
import Vuetify from 'vuetify/lib/framework';


Vue.use(Vuetify);
console.log(localStorage.getItem('dark'));
let drk = localStorage.getItem('dark') == 'true' ? true : false;

export default new Vuetify({
    theme: {
        dark: drk
    }
});

<template>
  <div>
    <v-sheet color="red darken-2" height="200" style="width: 100%">
      <v-container>
        <v-layout class="mt-14">
          <div class="white--text">
            <p class="text-h4 font-weight-bold">Hi {{ this.$store.state.user.name }},</p>
            <p class="text-body-1 font-weight-bold mt-n6 gray">Welcome to GRPanel3</p>
          </div>
        </v-layout>
      </v-container>
    </v-sheet>
    <v-container class="mt-n16">
      <v-card :loading="ban.loading" outlined>
        <v-card-text> Submit report </v-card-text>
        <v-form class="mx-4" ref="form">
          <v-text-field
            :disabled="ban.loading"
            v-model="ban.username"
            filled
            @input="ban.found = true"
            :rules="[(v) => !!ban.found || 'User not found']"
            hide-details="auto"
            label="Username"
          >
          </v-text-field>
          <v-textarea
            :disabled="ban.loading"
            v-model="ban.reason"
            filled
            rows="2"
            :rules="[(v) => !!v || 'Enter a reason']"
            class="mt-2"
            label="Reason"
            hide-details="auto"
          >
          </v-textarea>
          <v-row class="mx-4 mt-1 mb-n5">
            <v-switch class="mx-auto" v-model="ban.nsfw" label="NSFW"> </v-switch
          ></v-row>

          <fileUpload v-model="ban.proof"> </fileUpload>
        </v-form>
        <v-row class="pb-6 mx-4 mt-3">
          <v-btn
            text
            class="ml-auto"
            @click="report"
            :disabled="ban.loading"
            color="info"
          >
            Submit
          </v-btn></v-row
        ></v-card
      >

      <stack
        class="mt-2"
        :column-min-width="320"
        monitor-images-loaded
        v-if="!loading"
        :gutter-width="8"
        :gutter-height="8"
      >
        <stack-item v-for="(report, i) in reports" :key="i">
          <v-card outlined class="">
            <div class="rounded">
              <v-img
                style="cursor: pointer"
                class="rounded-t"
                v-ripple
                outlined
                v-for="(img, i) in images(report)"
                :key="i"
                aspect-ratio="1"
                tile
                :src="img"
                max-height="80px"
                @click="open(img)"
              >
              </v-img>
            </div>
            <div>
              <v-card-title> {{ report.username }} </v-card-title>
              <v-card-subtitle
                v-if="report.status === 'none'"
                class="mt-n5 mb-n8 grey--text font-weight-bold"
              >
                Pending
              </v-card-subtitle>
              <v-card-subtitle
                v-if="report.status === 'accepted'"
                class="mt-n5 mb-n8 green--text font-weight-bold"
              >
                Accepted {{ report.note ? "(" + report.note + ")" : "" }}
              </v-card-subtitle>
              <v-card-subtitle
                v-if="report.status === 'denied'"
                class="mt-n5 mb-n8 red--text font-weight-bold"
              >
                Denied {{ report.note ? "(" + report.note + ")" : "" }}
              </v-card-subtitle>
              <v-card-subtitle
                v-if="report.status === 'canceled'"
                class="mt-n5 mb-n8 red--text font-weight-bold"
              >
                Canceled
              </v-card-subtitle>

              <v-card-subtitle class="mt-n5"> {{ report.report }} </v-card-subtitle>
            </div>
            <v-expand-transition>
              <div>
                <v-card
                  v-for="(link, i) in report.proof.split(' ')"
                  :key="i"
                  outlined
                  class="mx-3 mb-3"
                  ripple
                  @click="openpage(link)"
                >
                  <v-card-title class="my-n2"> {{ link }}</v-card-title></v-card
                >
              </div>
            </v-expand-transition>
            <v-divider> </v-divider>
            <v-card-actions class="py-2">
              <v-btn
                text
                :disabled="report.status !== 'none'"
                @click="edit(report)"
                color="green"
              >
                Edit
              </v-btn>
              <v-btn
                text
                :disabled="report.status !== 'none'"
                @click="del(report)"
                color="red"
              >
                Cancel
              </v-btn></v-card-actions
            >
          </v-card>
        </stack-item>
      </stack>
    </v-container>

    <v-container v-if="editing.open" flex class="mx-auto" style="width: 100%">
      <v-overlay>
        <v-dialog v-model="editing.open" max-width="400px" max-height="400px">
          <v-card class="mx-auto">
            <v-card-title class="pb-4"> Report #{{ editing.data.id }} </v-card-title>
            <div class="mx-6">
              <v-text-field
                filled
                hide-details="auto"
                label="Proof"
                v-model="editing.data.proof"
              >
              </v-text-field>
              <v-textarea
                rows="3"
                class="rounded-0"
                filled
                label="Report"
                v-model="editing.data.report"
              />
            </div>
            <v-divider></v-divider>
            <v-layout class="px-2 py-2">
              <v-btn text @click="savereport" color="success"> Update </v-btn>
              <v-btn text @click="editing.open = false" color="error">
                Cancel
              </v-btn></v-layout
            >
          </v-card>
        </v-dialog>
      </v-overlay>
    </v-container>

    <v-container v-if="img.open" flex class="mx-auto" style="width: 100%">
      <v-overlay>
        <v-dialog v-model="img.open" max-width="1000px" max-height="1000px">
          <v-card class="mx-auto">
            <v-img :src="img.src">
              <v-card-actions>
                <v-spacer></v-spacer
                ><v-btn color="red" bottom plain @click="img.open = false"> Close </v-btn>
              </v-card-actions></v-img
            >
          </v-card>
        </v-dialog>
      </v-overlay>
    </v-container>

    <v-snackbar v-model="toast.open">
      {{ toast.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="toast.color"
          text
          v-bind="attrs"
          timeout="2000"
          @click="toast.open = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import { Stack, StackItem } from "vue-stack-grid";
import fileUpload from "@/components/fileUpload";
export default {
  name: "Home",

  components: {
    Stack,
    fileUpload,
    StackItem,
  },
  data: () => ({
    ban: {
      username: "",
      reason: "",
      proof: "",
      found: true,
      loading: false,
      nsfw: false,
    },
    model: [],
    expand: false,
    reports: [],
    img: {
      open: null,
      src: null,
    },
    toast: {
      message: "",
      color: "success",
      open: false,
    },
    editing: {
      open: false,
      data: {},
    },
    loading: true,
    unban: {
      username: "",
      found: true,
      loading: false,
    },
  }),
  methods: {
    async del(report) {
      let req;
      try {
        req = await this.$http.delete("/report/" + report.id);
      } catch (e) {
        this.toast.message = "Something went wrong";
        this.toast.color = "error";
        this.toast.open = true;

        return;
      }

      this.toast.message = "Sucessfully canceled  report";
      this.toast.color = "success";
      this.toast.open = true;
      report.status = "canceled";
      console.log(req);
    },
    edit(report) {
      this.editing.open = true;
      this.editing.data = report;
    },
    async report() {
      let req;
      try {
        req = await this.$http.post("/report", {
          proof: this.ban.proof || "N/A",
          report: this.ban.reason,
          target: this.ban.username,
          nsfw: this.ban.nsfw,
        });
      } catch (e) {
        console.log(e.response.status);
        if (e.response.status === 404) {
          this.toast.message = "User not found";
          this.toast.color = "error";
          this.toast.open = true;
          this.ban.found = false;
        } else {
          this.toast.message = "Something went wrong";
          this.toast.color = "error";
          this.toast.open = true;
        }
        return;
      }

      this.toast.message = "Sucessfully sent report";
      this.toast.color = "success";
      this.toast.open = true;
      this.loading = true;
      this.reports.unshift(req.data.data);
      this.ban = {
        username: "",
        reason: "",
        proof: "",
        found: true,
        loading: false,
        nsfw: false,
      };
      await new Promise((resolve) => setTimeout(resolve, 200));
      this.loading = false;
      console.log(req);
    },
    images(ban) {
      return ban.proof.split(" ").filter((i) => this.$isimage(i));
    },
    open: function (src) {
      this.img.src = src;
      this.img.open = true;
    },
    async savereport() {
      try {
        await this.$http.patch(`/report/${this.editing.data.id}`, {
          proof: this.editing.data.proof,
          reason: this.editing.data.report,
        });
      } catch (e) {
        this.toast.message = "Something went wrong";
        this.toast.color = "error";
        this.toast.open = true;
        this.editing.open = false;
        return;
      }

      this.toast.message = "Sucessfully updated report";
      this.toast.color = "success";
      this.toast.open = true;
      this.editing.open = false;
    },
    openpage: function (url) {
      window.open(url);
    },
  },

  async created() {
    let req = await this.$http.get("/reports/@me");
    console.log(req.data);
    this.reports = req.data
      .map((c) => {
        c.expand = false;
        return c;
      })
      .slice(0, 30);
    this.loading = false;
  },
};
</script>

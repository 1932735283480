<template>
  <div>
    <v-sheet color="red darken-2" height="200" style="width: 100%">
      <v-container>
        <v-layout class="mt-14">
          <div class="white--text">
            <p class="text-h4 font-weight-bold">Hi {{ this.$store.state.user.name }},</p>
            <p class="text-body-1 font-weight-bold mt-n6 gray">Welcome to GRPanel</p>
          </div>
        </v-layout>
      </v-container>
    </v-sheet>
    <v-container class="mt-n16">
      <v-row>
        <v-col>
          <v-card :loading="warning.loading" outlined>
            <v-card-text> Warning </v-card-text>
            <v-form class="mx-4" ref="form">
              <v-text-field
                :disabled="warning.loading"
                v-model="warning.username"
                filled
                @input="warning.found = true"
                :rules="[(v) => !!warning.found || 'User not found']"
                hide-details="auto"
                label="Username"
              >
              </v-text-field>
              <v-textarea
                :disabled="warning.loading"
                v-model="warning.reason"
                filled
                rows="2"
                :rules="[(v) => !!v || 'Enter a reason']"
                class="mt-2 "
                label="Reason"
                hide-details="auto"
              >
              </v-textarea>
            </v-form>
            <v-row class="pb-6 mx-4 mt-3">
              <v-btn
                text
                class="ml-auto"
                @click="cban"
                :disabled="warning.loading"
                color="info"
              >
                Warn
              </v-btn></v-row
            ></v-card
          >
        </v-col>
      </v-row>
      <v-card :loading="kick.loading" outlined class="mb-3 mt-2">
        <v-card-text> Kick user </v-card-text>
        <v-text-field
          :disabled="kick.loading"
          v-model="kick.username"
          filled
          class="mx-4"
          @input="kick.found = true"
          :rules="[(v) => !!kick.found || 'User not found']"
          hide-details="auto"
          label="Username"
        >
        </v-text-field>
        <v-textarea
          :disabled="kick.loading"
          v-model="kick.reason"
          filled
          rows="2"
          :rules="[(v) => !!v || 'Enter a reason']"
          class="mt-2 mx-4"
          label="Reason"
          hide-details="auto"
        >
        </v-textarea>
        <v-row class="mx-5 mb-4 mt-3">
          <v-btn class="elevation-0 ml-auto" @click="kickuser" color="success"> Kick user </v-btn>
        </v-row>
      </v-card>
      <v-card :loading="shout.loading" outlined class="mb-3">
        <v-card-text> Shout </v-card-text>
        <v-text-field
          :disabled="shout.loading"
          v-model="shout.shout"
          class="mx-4"
          filled
          hide-details="auto"
          label="Shout"
        >
        </v-text-field>
        
        <v-row class="mx-5 mb-4 mt-3">
          <v-btn class="elevation-0 ml-auto" @click="sendshout" color="success"> Shout </v-btn>
        </v-row>
      </v-card>

    </v-container>

    <v-snackbar v-model="toast.open">
      {{ toast.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="toast.color"
          text
          v-bind="attrs"
          timeout="2000"
          @click="toast.open = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
export default {
  name: "Home",

  components: {
    
  },
  data: () => ({
    loading: false,
    servers: [],
    warning: {
      username: "",
      reason: "",
      proof: "",
      time: "0",
      found: true,
      loading: false,
    },
    kick: {
      username: "",
      reason: "",
      loading: false,
    },
    dialog: {
      user: {},
      open: false,
    },
    shout: {
      shout: "",
      loading: false,
    
    },
    toast: {
      message: "",
      color: "success",
      open: false,
    },
  }),
  /*async created() {
    let req = await this.$http.get("/ingame");
    console.log(req.data);
    this.servers = req.data.sv;
    let connection = new WebSocket(`ws://localhost:3030/game`);

    connection.onopen = () => {
      console.log("[-] Connected to WS");
    };
    connection.onerror = (error) => {
      console.log(`WebSocket error: ${error}`);
    };
    connection.onmessage = (e) => {
      let data = JSON.parse(e.data);
      this.servers = data;
    };
  },*/

  methods: {
    opendialog(user) {
      this.dialog.user = user;
      this.dialog.open = true;
    },
    async cban() {
      this.warning.loading = true;
      try {
        await this.$http.post("/warn", {
          target: this.warning.username,
          reason: this.warning.reason,
          proof: this.warning.proof,
        });
      } catch (e) {
        this.toast.message = "Something went wrong";
        this.toast.color = "error";
        this.toast.open = true;
        return;
      }
      this.toast.message = "Sucessfully banned user";
      this.toast.color = "success";
      this.toast.open = true;
      this.warning = {
        username: "",
        reason: "",
        proof: "",
        time: "0",
        found: true,
        loading: false,
      };
    },
    async kickuser() {
      this.kick.loading = true;
      let req;
      try {
      req = await this.$http.post(`/kplayer/${this.kick.username}`, {
        reason: this.kick.reason,
      });
      } catch(e) {
        if (e?.response?.status == 404) {
          this.toast.message = "User not found";
          this.toast.color = "error";
          this.toast.open = true;
          return;
        }
      }
      console.log(req.data);
      this.toast.message = "Send messages to server";
      this.toast.color = "green";
      this.toast.open = true;
      this.kick.loading = false
    },
    async sendshout() {
      this.shout.loading = true;
       await this.$http.post("/shout", {
        shout: this.shout.shout,
      });
      this.toast.message = "sent messages to server";
      this.toast.color = "green";
      this.toast.open = true;
      this.shout.loading = false
    }
  },
};
</script>

<template>
  <div>
    <v-sheet color="red darken-2" height="200" style="width: 100%">
      <v-container>
        <v-layout class="mt-14">
          <div class="white--text">
            <p class="text-h4 font-weight-bold">Hi {{ this.$store.state.user.name }},</p>
            <p class="text-body-1 font-weight-bold mt-n6 gray">Welcome to GRPanel</p>
          </div>
        </v-layout>
      </v-container>
    </v-sheet>
    <v-container class="mt-n16">
      <v-card class="mb-3 pb-5">
        <v-card-text> User editing </v-card-text>
        <div v-if="!editing.isEditing">
          <v-text-field
            v-model="editing.username"
            label="Username"
            outlined
            class="mx-4"
            v-if="!editing.isEditing"
            hide-details="auto"
            required
          ></v-text-field>

          <v-row class="mx-4 mt-3">
            <v-btn
              text
              class="ml-auto"
              @click="getData"
              :disabled="editing.loading"
              color="info"
            >
              Check
            </v-btn>
          </v-row>
        </div>
        <div class="mt-n5" v-if="editing.isEditing">
          <v-layout>
            <v-avatar class="my-auto ml-4 mr-n2" size="25">
              <v-img :src="editing.user.pfp"></v-img>
            </v-avatar>
            <div>
              <v-card-text class="grey--text">@{{ editing.user.username }}</v-card-text>
            </div></v-layout
          >
          <v-text-field
            v-model="editing.data.gp"
            label="GP"
            @input="(s) => editing.data.gp = parseInt(s)"
            type="number"

            outlined
            class="mx-4"
            hide-details="auto"
            required
          ></v-text-field>
          <vue-json-editor
            v-model="editing.data"
            :show-btns="false"
            class="mx-4 mt-2"
            v-if="editing.isDeveloper"
            :expandedOnStart="true"
          ></vue-json-editor>
          <v-row class="mx-4 mt-3">
            <v-btn
              text
              class=""
              @click="editing.isEditing = false"
              :disabled="editing.loading"
              color="success"
            >
              Change user
            </v-btn>
            <v-btn
              text
              class="ml-auto"
              @click="saveData"
              :disabled="editing.loading"
              color="info"
            >
              Save
            </v-btn></v-row
          >
        </div>
      </v-card>
     
    </v-container>
    <v-snackbar v-model="toast.open">
      {{ toast.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="toast.color"
          text
          v-bind="attrs"
          timeout="2000"
          @click="toast.open = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
  </div>
</template>

<script>
import vueJsonEditor from "vue-json-editor";

export default {
  name: "Home",

  components: { vueJsonEditor },
  data: () => ({
    loading: false,
    editing: {
      isEditing: false,
      loading: false,
      username: "",
      data: {
        gp: 72000,
        rep: "",
        rawdata: {},
      },
      user: {
        username: "ItsWHOOOP",
        pfp: "",
      },
    },

    toast: {
      message: "",
      color: "success",
      open: false,
    },
  }),
  methods: {
    getData() {
      this.$http.get(`/gp/${this.editing.username}`).then((res) => {
        this.editing.data = res.data.data;
        this.editing.isDeveloper = res.data?.isDeveloper;

        this.editing.user = res.data.user;
        this.editing.isEditing = true;
      });
    },
    async saveData() {
      this.editing.loading = true;
      if (this.isDeveloper) {
        this.editing.data.gp = this.editing.gp;
      }
      console.log(this.editing.data);
      await this.$http.post(`/gp/${this.editing.user.username}`, this.editing.data);
      this.editing.loading = false;
      this.toast.message = "Saved!";
      this.toast.color = "success";
      this.toast.open = true;
    },
  },
};
</script>

<template>
  <div>
    <v-row class="mx-4 mt-1">
      <v-switch class="mx-auto" v-model="links" label="Images as links"> </v-switch
    ></v-row>

    <v-text-field
      :disabled="disabled"
      :value="input"
      @input="(i) => handleinput(i)"
      v-if="links"
      filled
      hide-details="auto"
      class="mt-3"
      label="Proof"
    >
    </v-text-field>
    <v-btn v-if="!links" block class="mt-3 info" @click="open = true">
      Select files
    </v-btn>
    <v-container v-if="open" flex class="mx-auto" style="width: 100%">
      <v-overlay>
        <v-dialog v-model="open" max-width="1000px" max-height="1000px">
          <v-card style="overflow: none" class="mx-auto">
            <div class="pt-6" id="up">
              <input class="d-none" type="file" @change="uploadFile" ref="file" />
              <v-card
                :loading="uploading"
                :disabled="uploading"
                ripple
                @click="handleFileImport"
                class="mx-6"
                outlined
              >
                <v-card-title> Upload files </v-card-title>
                <v-card-text class="mt-n5"> Upload files from your computer </v-card-text>
              </v-card>
            </div>
            <div class="thing" >
              <v-layout wrap class="pt-1 px-3">
                <v-col v-for="(img, i) in imgs" :key="i" cols="12" md="4" sm="6">
                  <v-card outlined :class="`${img.selected ? 'selected' : ''}`">
                    <div class="rounded">
                      <v-img
                        style="cursor: pointer"
                        class="rounded-t"
                        :src="img.url"
                        v-ripple
                        outlined
                        aspect-ratio="1"
                        tile
                        max-height="80px"
                      >
                      </v-img>
                    </div>
                    <v-layout @click="img.selected = !img.selected" v-ripple>
                      <v-card-title> {{ img.name }} </v-card-title>
                    </v-layout>
                  </v-card></v-col
                ></v-layout
              >
            </div>
            <v-divider class="mt-3" />
            <div class="py-3 ml-5">
              <v-layout>
                <v-spacer></v-spacer>
                <v-btn text color="red" @click="open = false" class="mr-1"> Close </v-btn>
                <v-btn text @click="close" color="green" class="mr-2"> Upload </v-btn>
              </v-layout>
            </div>
          </v-card>
        </v-dialog>
      </v-overlay>
    </v-container>
  </div>
</template>
<script>
export default {
  props: ["value", "disabled"],
  data: () => ({
    open: false,
    links: false,
    uploading: false,
    imgs: [],
    images: [],
  }),
  computed: {
    input() {
      return this.value;
    },
  },
  methods: {
    uploadFile() {
      this.uploading = true;
      this.images = this.$refs.file.files[0];
      console.log(this.images);
      const formData = new FormData();
      formData.append("file", this.images);
      const headers = { "Content-Type": "multipart/form-data" };
      this.$http.post("/siteupload/", formData, { headers }).then((res) => {
        console.log(res.data);
        let es = res.data.fdata;
        es.selected = false;
        this.imgs.unshift(es);
        this.uploading = false;
      });
    },
    handleinput(value) {
      console.log(value);
      this.$emit("input", value);
    },
    close() {
      this.open = false;
      let value = this.imgs
        .filter((img) => img.selected)
        .map((m) => m.url)
        .join(" ");
      console.log(value);
      this.$emit("input", value);
    },
    handleFileImport() {
      window.addEventListener("focus", { once: true });
      this.$refs.file.click();
    },
    submitFile() {
      const formData = new FormData();
      formData.append("file", this.images);
      const headers = { "Content-Type": "multipart/form-data" };
      this.$http.post("/siteupload/", formData, { headers }).then((res) => {
        console.log(res.data);
      });
    },
  },
  async created() {
    let images;
    try {
      images = await this.$http.get("/images/@me");
    } catch (e) {
      return;
    }
    console.log(images.data);
    this.imgs = images.data.map((img) => {
      return {
        ...img,
        selected: false,
      };
    }).slice(0, 40);
  },
};
</script>

<style>
.selected {
  outline-style: solid;
  outline-color: #1976d2;
  outline-offset: -2px;
  outline-width: 3px;
}

.thing {
  overflow-y:auto;
  height: 300px;
}

@media screen and (min-width: 800px) {
  .thing {
    height: 30rem
  }
}

.item {
  height: 70px !important;
}
</style>

<template>
  <div>
    <v-container>
      <v-layout v-if="!loading" class="mt-12">
        <v-avatar class="my-auto ml-3 mr-3" color="red darken-2" size="80">
          <v-img :src="data.pfp"></v-img>
        </v-avatar>
        <div class="my-auto">
          <p class="text-h4 my-auto font-weight-bold">{{ data.displayName }}</p>
          <p
            v-if="data.ruser != data.displayName"
            class="text-body-1 my-auto grey--text mt-n1 gray"
          >
            @{{ data.ruser }}
          </p>
        </div></v-layout
      >
      <v-tabs class="mt-7 mb-n3" v-model="tab" color="red darken-2">
        <v-tab href="#tab-1">Stats</v-tab>
      </v-tabs>
    </v-container>
    <v-divider class="mb-n2"></v-divider>
    <v-container class="mt-2">
      <v-card v-if="loading" outlined>
        <v-row class="mx-auto mb-5 mt-5">
          <v-progress-circular
            :size="50"
            color="amber"
            class="mx-auto"
            indeterminate
          ></v-progress-circular>
          <v-card-text class="text-center mt-2 mt-n2 mb-n5">
            Loading...
          </v-card-text>
        </v-row></v-card
      >
      <v-tabs-items v-model="tab" v-if="!loading">
        <v-tab-item :key="1" :value="'tab-' + 1">
          <v-select
            v-model="time"
            :items="options"
            outlined
            label="Time"
            class="mt-2 mb-n7"
          >
          </v-select>
          <v-row class="">
            <v-col order="last">
              <v-card outlined class="">
                <v-card-text class=""> Bans made </v-card-text>

                <p class="ml-3 mb-2 mt-5 text-h2 mt-n5">
                  {{ bans }}
                </p>
              </v-card>
            </v-col>
            <v-col order="last">
              <v-card outlined class="">
                <v-card-text class=""> Tickets </v-card-text>
                <p class="ml-3 mb-2 mt-5 text-h2 mt-n5">
                  {{ tickets }}
                </p>
              </v-card>
            </v-col>
            <v-col order="last">
              <v-card outlined class="">
                <v-tooltip bottom>
                  <template v-slot:activator="{ on, attrs }">
                    <v-progress-linear
                      background-color="red"
                      v-bind="attrs"
                      v-on="on"
                      color="green"
                      class="mb-n1"
                      :value="reportsStatusPrecentage"
                    ></v-progress-linear>
                  </template>
                  <span>Accepted: {{ acceptedReports }}, Denied: {{ deniedReports }}</span>
                </v-tooltip>
                <v-card-text class=""> Reports </v-card-text>
                <p class="ml-3 mb-2 mt-5 text-h2 mt-n5">
                  {{ reports.length }}
                </p>
              </v-card>
            </v-col>
          </v-row>
          <v-card outlined class="mt-3">
            <v-card-text class=""> Time spent in-game </v-card-text>
            <p class="ml-3 mb-2 mt-5 text-h2 mt-n5">{{ this.data.time }}</p>
          </v-card>
        </v-tab-item>
        <v-tab-item :key="2" :value="'tab-' + 2"> NO </v-tab-item>
      </v-tabs-items>
    </v-container>
  </div>
</template>
<script>
export default {
  name: "profile",
  data: () => ({
    loading: true,
    tab: 1,
    time: "All time",
    options: ["All time", "Month", "Last month"],
    data: {
      pfp: "",
      info: {
        displayName: "",
        username: "",
      },
    },
  }),
  computed: {
    bans: function () {
      if (this.time === "All time") {
        return this.data.bans.alltime;
      } else if (this.time === "Month") {
        return this.data.bans.month;
      } else {
        return this.data.bans.lastmonth;
      }
    },
    reports() {
      if (this.time === "All time") {
        return this.data.reports.alltime;
      } else if (this.time === "Month") {
        return this.data.reports.month;
      } else {
        return this.data.reports.lastmonth;
      }
    },
    tickets() {
      if (this.time === "All time") {
        return this.data.tickets.alltime;
      } else if (this.time === "Month") {
        return this.data.tickets.month;
      } else {
        return this.data.tickets.lastmonth;
      }
    },
    reportsStatusPrecentage() {
      const reports = this.reports.filter((report) => report.status !== "none");
      let open = reports.filter(
        (report) => report.status === "accepted"
      ).length;
      //let denied = reports.filter((report) => report.status === "denied").length;
      let total = reports.length;
      let openPrecentage = Math.round((open / total) * 100);
      console.log(reports);
      return openPrecentage;
    },
    acceptedReports() {
      const reports = this.reports.filter((report) => report.status !== "none");
      let open = reports.filter(
        (report) => report.status === "accepted"
      ).length;
      return open;
    },
    deniedReports() {
      const reports = this.reports.filter((report) => report.status !== "none");
      let denied = reports.filter((report) => report.status === "denied").length;
      return denied;
    },
  },
  async created() {
    let req = await this.$http.get("/profile/" + this.$route.params.id);
    console.log(req.data);
    this.data = req.data;
    this.loading = false;
  },
  methods: {},
};
</script>

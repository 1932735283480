<template>
  <div>
    <v-sheet color="red darken-2" height="200" style="width: 100%">
      <v-container>
        <v-layout class="mt-14">
          <div class="white--text">
            <p class="text-h4 font-weight-bold">Hi {{ this.$store.state.user.name }},</p>
            <p class="text-body-1 font-weight-bold mt-n6 gray">Welcome to GRP images</p>
          </div>
        </v-layout>
      </v-container>
    </v-sheet>
    <v-container class="mt-n16">
      <v-row>
        <v-col>
          <v-card v-if="$store.state.user.permission >= 2" :loading="sloading" outlined>
            <v-card-text> Images </v-card-text>

            <v-row class="pb-7 mx-4">
              <v-btn
                @click="download"
                class="ml-auto"
                block
                :disabled="sloading"
                color="info"
              >
                Download loader
              </v-btn></v-row
            ></v-card
          ></v-col
        >
      </v-row>

      <v-layout wrap class="mx-n3">
        <v-col v-for="(img, i) in imgs" :key="i" cols="12" xl="3" md="4" sm="6">
          <v-card outlined class="" :loaidng="img.loading">
            <div class="rounded">
              <v-img
                style="cursor: pointer"
                class="rounded-t"
                :src="img.url"
                v-ripple
                outlined
                aspect-ratio="1"
                tile
                max-height="80px"
                @click="open(img.url)"
              >
              </v-img>
            </div>
            <v-card-title> {{ img.name }} </v-card-title>
            <v-divider />
            <v-card-actions>
              <v-spacer />
              <v-btn @click="deleteimage(img)" :disabled="img.loading" text color="error">
                Delete
              </v-btn>
            </v-card-actions>
          </v-card></v-col
        ></v-layout
      >
    </v-container>
    <v-snackbar v-model="toast.open">
      {{ toast.message }}

      <template v-slot:action="{ attrs }">
        <v-btn
          :color="toast.color"
          text
          v-bind="attrs"
          timeout="2000"
          @click="toast.open = false"
        >
          Close
        </v-btn>
      </template>
    </v-snackbar>
    <v-container v-if="img.open" flex class="mx-auto" style="width: 100%">
      <v-overlay>
        <v-dialog v-model="img.open" max-width="1000px" max-height="1000px">
          <v-card class="mx-auto">
            <v-img :src="img.src">
              <v-card-actions>
                <v-spacer></v-spacer
                ><v-btn color="red" bottom plain @click="img.open = false"> Close </v-btn>
              </v-card-actions></v-img
            >
          </v-card>
        </v-dialog>
      </v-overlay>
    </v-container>
  </div>
</template>

<script>
export default {
  name: "Home",

  components: {},
  data: () => ({
    search: {
      found: false,
      text: "",
    },
    imgs: [],
    toast: {
      message: "",
      color: "success",
      open: false,
    },
    img: {
      open: null,
      src: null,
    },
    results: {},

    sloading: false,
  }),
  async mounted() {
    let images;
    try {
      images = await this.$http.get("/images/@me");
    } catch (e) {
      return;
    }
    this.imgs = images.data.map(img => ({
      ...img,
      loading: false
    })).slice(0, 40);
  },
  methods: {
    images(ban) {
      return ban.proof.split(" ").filter((i) => this.$isimage(i));
    },
    open: function (src) {
      this.img.src = src;
      this.img.open = true;
    },
    gettime: function (date) {
      let datex = new Date(date);
      console.log(datex.toLocaleString("en-US", { timeZone: "America/New_York" }));
      return datex.toLocaleString("en-US", { timeZone: "America/New_York" });
    },
    async deleteimage(img) {
      img.loading = true
      try {
        await this.$http.delete(`/image/${img.name}`);
      } catch (e) {
        img.loading = false
        if (e.response.status == 406) {
          this.toast.message = "You can't delete this image sinse its used in reports and or bans";
          this.toast.color = "error";
          this.toast.open = true;
          return;
        }
        this.toast.message = "Error deleting image";
        this.toast.color = "error";
        this.toast.open = true;
        return;
      }
      img.loading = false
      this.toast.message = "Image deleted";
      this.toast.color = "success";
      this.toast.open = true;
      this.imgs.splice(this.imgs.indexOf(img), 1);
    },
    openpage: function (url) {
      window.open(url);
    },
    download: function () {
      window.open(`https://grplaza.org/api/images/download`);
    },
    async searchs() {},
  },
};
</script>
